<section id="d-vitamin" class="py-5">
    <div class="container pt-5">
        <div class="row pt-5">
            <div class="col-md-4">
                <div class="content-title">
                    <h1>Vitamín D</h1>
                    <img src="/assets/images/pages/d-vitamin/d-vitamin.png" alt="d-vitamin">
                </div>
            </div>
            <div class="col-md-8">
                <div class="page-short">
                    <p>V posledných rokoch boli objavené účinky vitamínu D na imunitný systém - okrem mnohých ďalších prospešných vlastností.</p>
                </div>
                <div class="content">
                    <p>Vitamín D sa biologicky vyrába v tele slnečným žiarením a nájdeme ho tiež v rôznych potravinách. Ide napríklad o mlieko, lososa, olej z tresčej pečene, vajcia a huby.</p>
                    <p>Naše telo však nie je schopné v zimných mesiacoch produkovať dostatok vitamínu D, preto je dôležité ho doplňovať. Nedostatok vitamínu D môže viesť k úbytku kostnej hmoty a narušeniu rovnováhy vápnika.</p>
                    <h3>Vitamín D prispieva k:</h3>
                    <ul>
                        <li>na normálne vstrebávanie / využitie vápniku a fosforu</li>
                        <li>udržiavať normálnu hladinu vápnika v krvi</li>
                        <li>udržiavať zdravé kosti</li>
                        <li>udržiavať zdravé svalové funkcie</li>
                        <li>udržiavať normálne zuby</li>
                        <li>pre normálne fungovanie imunitného systému</li>
                        <li>na delenie buniek</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="col-xs-4 back">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" ><i class="fas fa-chevron-left"></i> <span> Prejdite na hlavnú stránku</span></a>
            </div>
            <div class="col-xs-8 title text-right">
                <span>Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný štýl.</span>
            </div>
        </div>
    </div>
</section>