<section id="c-vitamin" class="py-5">
    <div class="container pt-5">
        <div class="row pt-5">
            <div class="col-md-4">
                <h1>Vitamín C</h1>
            </div>
            <div class="col-md-8">
                <div class="page-short">
                    <p>Z vitamínov je jedným z hlavných „spojencov“ nášho imunitného systému vitamín C. Aj tak ním plytváme? Odpoveď je ÁNO!</p>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <div class="content-title">
                    <h2>VEĽKÝ „PROBLÉM VITAMÍNU C“ - VÁŽNE OBMEDZENIA ABSORPCIE</h2>
                </div>
            </div>
            <div class="col-md-8">
                <div class="content">
                    <p>Od objavenia vitamínu C sa uskutočnilo množstvo štúdií, ktoré zisťovali, ako efektívne je telom absorbovaný. Podľa výskumov: pretože je vitamín C rozpustný vo vode, telo si ho môže ukladať iba v obmedzenej miere. Bežne sa vie vstrebať maximálne 200 mg vitamínu C do tela. Čím vyššia dávka bežného vitamínu C sa dostane do tela tým menej ho vieme zužitkovať.</p>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <div class="content-title">
                    <h2>ČO SA STANE S NEPOUŽITÝM VITAMÍNOM C?</h2>
                    <img src="/assets/images/pages/c-vitamin/c-vitamin.png" alt="c-vitamin">
                </div>
            </div>
            <div class="col-md-8">
                <div class="content">
                    <p>Časť absorpcie vitamínu C sa deje v ústnej sliznici a v žalúdku, ale vo významnom množstve v tenkom čreve.</p>
                    <p>Vitamín C má toľko času na vstrebávanie, koľko prechádza tráviacim systémom a vylučuje sa.</p>
                    <p>Ovplyvňuje dobu zdržania vitamínu C v zažívacom systéme, saturáciu žalúdka v čase príjmu a rôzny metabolizmus človeka, preto sa účelne podáva v časových intervaloch.</p>
                    <h4>Dĺžka pobytu:</h4>
                    <ul>
                        <li>v žalúdku 0,5-3 hodiny</li>
                        <li>v tenkom čreve 6 - 8 hodín po požití.</li>
                    </ul>
                    <br>
                    <p>Nevstrebaný vitamín C sa dostáva do hrubého čreva. Časté sú nepríjemné vedľajšie účinky, ako je pálenie záhy, zvýšené močenie alebo dokonca hnačka. Vitamín C sa vylučuje priamo do stolice z hrubého čreva. Ukazuje sa tiež, že užívanie vyšších dávok spôsobí, že náš moč bude obzvlášť bohatý na vitamín C, zatiaľ čo naše telá budú naďalej „hladovať“ po vitamíne C. A veľké množstvo vyčerpaného vitamínu C môže dokonca podporovať tvorbu obličkových kameňov.</p>
                    <h3>Užívat NOVO C PLUS<sup>&reg;</sup> KOMPLEX<sup>&reg;</sup> A IMMUN<sup>&reg;</sup> sa ODPORÚČA NA:</h3>
                    <ul>
                        <li>podporu fungovania imunitného systému</li>
                        <li>na zníženie únavy a vyčerpanosti</li>
                        <li>normálne fungovanie nervového systému</li>
                        <li>na ochranu buniek pred oxidačným stresom</li>
                        <li>udržanie normálnych psychologických funkcií</li>
                        <li>na normálne metabolické procesy produkujúce energiu</li>
                        <li>správnu tvorbu kolagénu a tým na udržanie normálneho stavu a funkcie pokožky, zubov a ďasien, kostí a chrupaviek, ako aj krvných ciev</li>
                        <li>zvýšenie absorpcie železa</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="col-xs-4 back">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" ><i class="fas fa-chevron-left"></i> <span> Prejdite na hlavnú stránku</span></a>
            </div>
            <div class="col-xs-8 title text-right">
                <span>Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný štýl.</span>
            </div>
        </div>
    </div>
</section>