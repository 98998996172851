<section id="liposomal-technology" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>LIPOZOMÁLNA TECHNOLÓGIA</h1>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Rodina produktov Novo C lipozomálnych vitamínov C je novou
            generáciou vitamínov C. Novo C plus, je komplexný vitamín C ktorý
            je, účinnejši viac ako tradičné vitamíny C a je účinnejši ako
            vitamín C s postupným uvoľňovaním Novo C predstavuje
            najefektívnejšiu formu vstrebávania vitamínu C<sup>1.</sup>
          </p>
          <p>
            Lipozomálna technológia je jedným z najnovších technologických
            objavov, ktoré používajú lekárski vedci na dodávanie dôležitých
            liekov do správnych orgánov.
          </p>
          <p>
            Je to tak preto, lebo táto technológia umožňuje cielené vstrebávanie
            v rámci organizmu.
          </p>
          <img
            src="/assets/images/pages/liposomal/liposzomalis.png"
            alt="Liposzómális"
          />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>ČO SÚ TO LIPOZÓMY?</h2>
          <img
            src="/assets/images/pages/liposomal/liposoma.png"
            alt="Liposzóma"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
            tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
            vitamín C zabalený do obalu podobnému bunkovej membráne a vytára
            guľôčky a lipozómy veľkosti nano.
          </p>
          <h4>TIETO POVRCHY OKOLO VITAMÍNU C:</h4>
          <ul>
            <li>
              fosfolipidové vrstvy okolo poskytujú ochrannú vrstvu pre vitamín C
              počas tráviaceho procesu
            </li>
            <li>umožňujú fúziu lipozómu s bunkami</li>
            <li>umožňujú efetívne vstrebávanie vitamínu C a jeho využitie</li>
          </ul>
          <h3>ČO JE MEMBRÁNOVÁ FÚZIA?</h3>
          <p>
            Membránová fúzia je spôsob, ako sa obal obklopujúci lipozómy spája s
            bunkami v našom tele.
          </p>
          <p>
            Bunky a lipozóm sú ohraničené rovnakou dvojitou fosfolipidovou
            vrstvou, ktorá sa navzájom priťahuje.
          </p>
          <p>
            Lipozómy dodávajú použité liečivo do buniek membránovou fúziou,
            takže množstvo uvoľňovania účinnej látky nieje obmedzené.
          </p>
          <img src="/assets/images/pages/membran.png" alt="" />
          <p>
            Takže sa nemusíme báť, že v kúpeľni „uvidíme“ veľa vitamínu C,
            pretože vitamín C sa v tele využíva a nevylučuje močom. Takto môže
            skutočne mať priaznivý vplyv na imunitný systém. A nezaťažuje to ani
            tráviaci systém: napríklad sa u vás neobjaví pálenie záhy alebo
            zvýšené močenie.
          </p>
          <h3>ČO ZNAMENÁ BIO-VYUŽITIE?</h3>
          <p>
            Biologická dostupnosť je rýchlosť a spôsob akosa účinná zložka
            uvoľňuje z kapsuli a sprístupňuje v krvi. Dáta sú zaradené do grafu
            a potom sú porovnané oblasti pod krivkami.
          </p>

          <p>
            Biologická dostupnosť lipozomálneho vitamínu C je dvakrát vyššia ako
            biologická dostupnosť bežných vitamínov C. To znamená, že za
            jednotkový čas sa môže vstrebať väčšie množstvo, môže zostať v krvi
            dlhší čas, takže plocha pod krivkou je 2x väčšia ako plocha
            tradičného vitamínu C.
          </p>
          <!-- <img src="/assets/images/pages/liposomal/kicsi-de-eros.png" alt="Kicsi, de bizonyítottan erős!"> -->
          <img src="/assets/images/pages/liposomal/lip2.png" alt="" />
          <h3>TAK ÚČINNÝ VIT C, ŽE NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h3>
          <p>
            Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
            pretože účinná látka sa dostane tam, kde skutočne potrebuje
            pracovať. Už jedna kapsula Novo C plus<sup>&reg;</sup> 300 mg denne
            prispieva k: účinnej podpore imunitného systému, znižovaniu únavy a vyčerpanosti, zdraviu pokožky, ďasien, kostí a chrupaviek.
          </p>
          <h3>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h3>
          <p>
            Klinická štúdia z roku 2015 potvrdila, že Novo C plus<sup
              >&reg;</sup
            >
            sa absorbuje v čreve a vstupuje do obehu vo väčšej miere. Telo má
            teda počas dňa k dispozícii väčšie množstvo vitamínu C, čím sa
            lepšie prispôsobuje zvýšeným potrebám tela.
          </p>
          <h3>POSKYTUJE DLHODOBÝ ÚČINOK!</h3>
          <p>Vitamín C užívajte nepretržite minimálne 12 hodín.</p>
          <p>
            V prípade lipozomálneho vitamínu C sa dá očakávať nielen dlhšie
            uvoľňovanie liečiva, ale vďaka absorpčnému mechanizmu lipozómu je
            možné získať viac výhod, pri rovnakom príjme liečiva sa dajú
            dosiahnuť vyššie plazmatické hladiny.
          </p>
          <h3>VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI</h3>
          <p>Obsahuje prírodný šípkový extrakt a citrusové bioflavonoidy.</p>
        </div>
      </div>
    </div>
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
