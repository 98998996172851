<section id="zinc" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>ZINOK</h1>
          <img src="/assets/images/pages/main/zinc.png" alt="zinc" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Zinok je minerál, ktorý sa odporúča konzumovať v malom množstve, ale
            je nevyhnutný pre normálne fungovanie nášho tela a imunitného
            systému.
          </p>
        </div>
        <div class="content">
          <p>
            Odporúčaná denná referenčná hodnota pre zinok je 10 mg pre
            dospelých. Zinok sa nachádza v potravinách bohatých na bielkoviny,
            ako je mlieko, vajcia a mäso, špargľa a ryby.
          </p>
          <p>
            Pri tepelnej úprave jedla sa môže stratiť významná časť zinku, alebo
            tí, ktorí vedú aktívny životný štýl, môžu prísť o tento dôležitý
            minerál nadmerným potením, preto je dôležité hodopíňať.
          </p>
          <h3>Zinok sa podieľa na:</h3>
          <ul>
            <li>udržiavaní normálnej acidobázickej rovnováhy</li>
            <li>v normálnom metabolizme sacharidov</li>
            <li>udržiavaní normálneho duševného fungovania</li>
            <li>pri normálnej syntéze DNA</li>
            <li>udržiavaní normálnej plodnosti a reprodukcie</li>
            <li>v normálnom metabolizme makroživín</li>
            <li>pri normálnom metabolizme mastných kyselín</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
