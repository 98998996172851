<section id="contact">
  <div class="container pt-5">
    <div class="row pt-5 mt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Kontakt</h1>
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            ODSTÚPENIE PREDBEŽNEJ ČERPACEJ SPOLOČNOSTI? ODSTÚPENIE ZAMESTNANCOV:
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-md-8">
        <div class="content">
          <h4>POŠLITE NÁM SPRÁVU</h4>
          <form [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="name">NÁZOV*</label>
              <input type="text" name="name" formControlName="name" required />
            </div>
            <div class="form-group">
              <label for="email">E-MAIL*</label>
              <input
                type="email"
                name="email"
                formControlName="email"
                required
              />
            </div>
            <div class="form-group">
              <label for="subject">PREDMET*</label>
              <input type="text" name="subject" formControlName="subject" />
            </div>
            <div class="form-group">
              <label for="message">SPRÁVA*</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                formControlName="message"
              ></textarea>
            </div>
            <div class="form-group">
              <input
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                formControlName="privacyPolicy"
              />
              <label for="privacyPolicy" class="privacyPolicy">
                VYHLASUJEM, ŽE SOM ÚPLNE VEDEL, A POROZUMEL SOM
                <a
                  href="/assets/GDPR-Penta-Pharma-SRO-2021-novocSK.pdf"
                  target="_blank"
                  style="text-decoration: underline"
                  >OBSAHU TÝCHTO INFORMÁCIÍ</a
                >
                O OCHRANE ÚDAJOV, A DOBROVOĽNE PRISPIEVAM K ZAOBCHÁDZANIU S
                OSOBNÝMI ÚDAJMI.
              </label>
              <br />
            </div>
            <button
              class="btn"
              type="submit"
              [disabled]="emailFormGroup.invalid || isLoadingBtn"
            >
              Posielam
            </button>
            <p
              class="error-msg"
              *ngIf="errorMessage"
              [innerHTML]="errorMessage"
            ></p>
          </form>
        </div>
      </div>
      <div class="col-md-4">
        <div class="content pt-5">
          <h3>TELEFÓN</h3>
          <p>+421 908 535 592</p>
          <br />
          <h3>E-MAIL</h3>
          <p>info@novoc.sk</p>
          <br />
          <h3>TITLE</h3>
          <p>
            Penta Pharma s.r.o.<br />Námestie Kossutha 3087/124<br />Komárno 945
            01
          </p>
          <p>
            <strong>IČO</strong>: 53117794<br />
            <strong>DIČ</strong>: 2121319365
          </p>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
